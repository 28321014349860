import { LogoutOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Badge, Col, Image, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import cart from '../../../assets/retail/icons/cart.svg';
// import logo from '../../../assets/retail/icons/logo.svg';
// import kotak from '../../../assets/retail/images/kotak.png';
import { useLocation } from 'react-router-dom';
import { authActions, updateCartCount } from '../../../store/auth';
import { getRetailAccessToken, setRetailAxiosHeaders } from '../../../utils/helper';
import { trackData } from '../../../utils/trackers';
import { CustomModal } from '../../Modals/CustomModal';
import { successNotification } from '../../UI/Toast/Toast';
import LoginModal from '../LoginModal/LoginModal';
import './_header.scss';
import ToDashboard from '../../UI/Header/ToDashboard';
import '../../UI/Header/_header.scss';
import TextBackCTA from './TextBackCTA';

const Header: React.FC = () => {
  const navigate = useNavigate(),
    [searchParams, setSearchParams] = useSearchParams(),
    dispatch = useDispatch(),
    location = useLocation(),
    [partner, setPartner] = useState(undefined as any),
    // partner =  matchPath(pathname, routePattern),
    // { partner } = useParams(),
    // partner = window.location.href?.split('/')[4],
    logoutHandler = () => {
      dispatch(authActions.retailLogout());
      navigate(partner ? `retail-partner/${partner}` : '');
      successNotification('Logged out successfully');
      setModalVisible(false);
    },
    cartCount = useSelector((state: any) => state?.auth?.cartCount),
    organisation_uuid = useSelector((state: any) => state?.auth?.organisation_uuid),
    isLoggedIn = getRetailAccessToken(),
    // isLoggedIn = useSelector((state: any) => state?.auth?.isLoggedIn),
    viewCart = () => {
      ReactGA.event('view_cart');
      navigate(partner ? `retail-partner/${partner}/checkout` : 'retail/checkout');
    },
    confirmHandler = () => {
      setIsLoginModalOpen(false);
    },
    cancelHandler = () => {
      setIsLoginModalOpen(false);
    },
    // [data, setData] = useState([] as any),
    themeDetails = useSelector((state: any) => state.theme),
    [modalVisible, setModalVisible] = useState(false),
    [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setRetailAxiosHeaders();
      if (!searchParams.get('order_id') && (partner || organisation_uuid)) {
        // console.log('called')
        dispatch(
          updateCartCount({
            ...(partner ? { slug: partner } : {}),
            ...(organisation_uuid && !partner ? { organisation_id: organisation_uuid } : {}),
          })
        );
      }
    }
  }, [isLoggedIn, partner, organisation_uuid]);

  useEffect(() => {
    const routePattern = '/retail-partner/:partner/*';
    const match = matchPath(routePattern, location.pathname);

    if (match) {
      const partner = match?.params?.partner;
      setPartner(partner);
    }
  }, [location]);

  return (
    <div>
      <ToDashboard />
      <div className="retail-header">
        <div className="retail-header-strip">
          <CustomModal
            isModalVisible={modalVisible}
            confirmHandler={logoutHandler}
            cancelHandler={() => {
              setModalVisible(false);
            }}
            title={`Are you sure you want to logout?`}
            cancelButtonText="NO"
            confirmButtonText="YES"
          />
          {isLoginModalOpen && (
            <LoginModal
              cancelHandler={() => cancelHandler()}
              isModalOpen={isLoginModalOpen}
              confirmHandler={confirmHandler}
            />
          )}
          <div className="retail-container">
            <Row justify="space-between" align="middle">
              <Col
              // className='show-on-web'
              >
                <Image
                  src={
                    partner === 'suraksha-qr'
                      ? 'https://healthysure-staging.s3.ap-south-1.amazonaws.com/temp/sq_tp.png'
                      : partner === 'tushil'
                      ? 'https://s3.ap-south-1.amazonaws.com/healthysure-production/ed95ff69-f43f-4435-aabc-d97ec705304e.jpeg?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXMH4HGHRI3W5ELXC%2F20250214%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20250214T082942Z&X-Amz-Expires=36000&X-Amz-SignedHeaders=host&X-Amz-Signature=959d46044c9a1491174ffd6d90144312f0ed672b9a33f2870ffaca51f4780c26'
                      : themeDetails?.logoUrl
                  }
                  // src={rp}
                  preview={false}
                  onClick={() => navigate(partner ? `retail-partner/${partner}` : 'retail')}
                  className={`cta logo-image ${partner === 'suraksha-qr' ? 'sq-logo' : ''}`}
                />
              </Col>
              {/* <Col className='show-on-mobile'>
          <Image
            src={logoShort}
            preview={false} onClick={() => navigate(`/${partner}`)} className='cta' />
        </Col> */}
              <Col>
                <Row gutter={32} align={'middle'} justify="end">
                  {isLoggedIn && (
                    <>
                      <Col className='show-on-web'>
                        <TextBackCTA />
                      </Col>
                      <Col>
                        <Badge count={cartCount}>
                          <Image
                            src={cart}
                            preview={false}
                            className="cta"
                            onClick={() => {
                              trackData('View Cart', { Location: 'header' });
                              viewCart();
                            }}
                          />
                        </Badge>
                      </Col>
                      <Col>
                        <Tooltip title="Logout">
                          <LogoutOutlined className="logout-icon cta" onClick={() => setModalVisible(true)} />
                        </Tooltip>
                      </Col>
                    </>
                  )}
                  {!isLoggedIn && (
                    <Col>
                      <Image
                        src={cart}
                        preview={false}
                        className="cta"
                        onClick={() => {
                          trackData('Open Login Modal', { Location: 'header' });
                          setIsLoginModalOpen(true);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
